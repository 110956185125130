<template>
  <FormulateForm v-model="values" :name="formName" :schema="schema" />
</template>

<script>
export default {
  name: 'AuthMessages',
  data() {
    return {
      values: {}
    };
  },
  computed: {
    formName() {
      return this.$route.meta.schema;
    },
    schema() {
      const schemas = {
        authResetLinkSent: [
          {
            component: 'p',
            children: this.$t('reset_link_sent_copy'),
            class: 'my-4'
          },
          {
            component: 'router-link',
            to: { name: 'AuthResetRequest' },
            class: 'tg-mobile-link-small md:tg-desktop-link link',
            children: this.iconLabel('fa-redo', 'Resetlink nogmaals versturen')
          }
        ],
        authResetSuccess: [
          {
            component: 'p',
            children: this.$t('reset_password_success_copy'),
            class: 'my-4'
          },
          {
            component: 'FormulateErrors'
          },
          {
            component: 'router-link',
            to: { name: 'AuthLogin' },
            class: 'tg-mobile-link-small md:tg-desktop-link link',
            children: this.iconLabel('fa-user', 'Return to login page')
          }
        ]
      };

      return schemas[this.formName];
    }
  },
  methods: {
    iconLabel(icon, label) {
      return [
        {
          component: 'i',
          class: 'far mr-2 ' + icon
        },
        {
          component: 'span',
          children: label
        }
      ];
    }
  }
};
</script>
